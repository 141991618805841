import React, { useEffect, useState } from "react";
import { ClientOnly } from "../../react/client-only";
import { RequestFormModal } from "../modalform";
import Header, { AssistantHeader, AssistantHeaderMobile } from "../Header";
import Footer from "../Footer";
import { useMedia } from "use-media";
//import "ti-icons/css/themify-icons.css";
//import "bootstrap/dist/css/bootstrap.min.css";

export default function Layout(props) {
  const [plan, setPlan] = useState("");
  const [showThanksPage, setShowThanksPage] = useState(false);
  const isMobile = useMedia({ maxWidth: "520px" });
  const isTab = useMedia("(min-width: 521px) and (max-width: 1024px)");

  return (
    <ClientOnly>
      <div className="wrapper_page">
        {plan == "" ? null : (
          <RequestFormModal
            setPlan={setPlan}
            plan={plan}
            showThanksPage={showThanksPage}
            setShowThanksPage={setShowThanksPage}
            fromPage={props.fromPage}
          />
        )}
        {props.logoFor === "ASSISTANT" && !isTab && !isMobile ? (
          <AssistantHeader
            backgroundColor={props.backgroundColor}
            logoFor={props.logoFor}
            logoAction={props.logoAction}
            hideMobileNavButton={props.hideMobileNavButton}
            betaClick={() => setPlan("homepage")}
          />
        ) : props.logoFor === "ASSISTANT" && isMobile ? (
          <AssistantHeaderMobile
            backgroundColor={props.backgroundColor}
            logoFor={props.logoFor}
            logoAction={props.logoAction}
            hideMobileNavButton={props.hideMobileNavButton}
            betaClick={() => setPlan("homepage")}
          />
        ) : (
          <Header
            backgroundColor={props.backgroundColor}
            logoFor={props.logoFor}
            logoAction={props.logoAction}
            hideMobileNavButton={props.hideMobileNavButton}
            betaClick={() => setPlan("homepage")}
          />
        )}

        {props.children}
        <Footer product={props.product} />
      </div>
    </ClientOnly>
  );
}

export function LayoutOnServer(props) {
  const [plan, setPlan] = useState("");
  const [showThanksPage, setShowThanksPage] = useState(false);

  return (
    <div className="wrapper_page">
      {plan == "" ? null : (
        <RequestFormModal
          setPlan={setPlan}
          plan={plan}
          showThanksPage={showThanksPage}
          setShowThanksPage={setShowThanksPage}
          product
          fromPage={props.fromPage}
        />
      )}
      <Header
        backgroundColor={props.backgroundColor}
        logoFor={props.logoFor}
        betaClick={() => setPlan("homepage")}
      />
      {props.children}
      <Footer product={props.product} />
    </div>
  );
}
